const Cookies = () => {
  return (
    <table className="table is-narrow is-size-7">
      <thead>
        <tr>
          <th>Cookie</th>
          <th>Name</th>
          <th>Expiry</th>
          <th>Purpose</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Cookie preference</td>
          <td>cookieConsent</td>
          <td style={{ whiteSpace: 'nowrap' }}>1 year</td>
          <td>
            This cookie is used to remember a user’s choice about cookies on our website. Where users have previously
            indicated a preference, that user’s preference will be stored in this cookie.
          </td>
        </tr>
        <tr>
          <td>YouTube</td>
          <td>
            CONSENT <br />
            PREF <br />
            VSC <br />
            VISITOR_INFO1_LIVE <br />
            remote_sid
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            2 years <br />
            8 months <br />
            Session <br />
            8 months <br />
            Session
          </td>
          <td>
            We embed videos from YouTube using YouTube’s privacy-enhanced mode. This mode may set cookies on your
            computer once you click on the YouTube video player, but YouTube will not store personally-identifiable
            cookie information for playbacks of embedded videos using the privacy-enhanced mode. Read more at YouTube’s
            embedding videos{' '}
            <a href="https://support.google.com/youtube/answer/171780?hl=en-GB" target="_blank">
              information page
            </a>
            .
          </td>
        </tr>
        <tr>
          <td>Stripe</td>
          <td>
            _stripe_mid
            <br />
            _stripe_sid
            <br />m
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            1 year <br />
            1 year <br />2 years
          </td>
          <td>
            These cookies are necessary for making payment card transactions on our website. The service is provided by
            Stripe.com which allows online transactions without storing any payment card information. You can read more
            about how Stripe use cookies{' '}
            <a href="https://stripe.com/cookies-policy/legal" target="_blank">
              here
            </a>
            .
          </td>
        </tr>
        <tr>
          <td>Universal Analytics (Google)</td>
          <td>
            _ga
            <br />
            _gat
            <br />
            _gid
            <br />
            _gat
            <br />
            _gtag_[ID of website]
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            2 years
            <br /> 1 minute
            <br /> Session
            <br /> 2 years
          </td>
          <td>
            These cookies are used to collect information about how visitors use our website. We use the information to
            help us improve the website, track the performance of our advertising campaigns on Google and target
            advertising to Google users. The cookies collect information in a way that does not directly identify
            anyone, including the number of visitors to the website, where visitors have come to the website from
            (including ads), the pages they visited and the products they have purchased. Read Google's overview of{' '}
            <a href="https://support.google.com/analytics/answer/6004245" target="_blank">
              privacy and safeguarding data
            </a>
            .
          </td>
        </tr>
        <tr>
          <td>Bing (Microsoft)</td>
          <td>
            _uetsid
            <br /> _uetvid
            <br /> muid
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            1 day
            <br /> 1 year
            <br /> 1 year
          </td>
          <td>
            These cookies are used by Bing to track visits across websites. This data is used to track purchases from
            advertising on Bing and to remarket Bing adverts at our visitors. You can find out more about how Bing uses
            cookies{' '}
            <a href="https://www.docracy.com/0fap0koedur/bing-com-privacy-policy-tos" target="_blank">
              here
            </a>
            .
          </td>
        </tr>
        <tr>
          <td>Facebook (Meta)</td>
          <td>_fbp</td>
          <td style={{ whiteSpace: 'nowrap' }}>3 months</td>
          <td>
            This cookie is used by Facebook to track visits across websites. This data is used to track purchases from
            advertising on Facebook and to remarket Facebook adverts at our visitors of our site. You can{' '}
            <a href="https://www.facebook.com/policies/cookies/" target="_blank">
              find out more
            </a>{' '}
            about how Facebook uses cookies.
          </td>
        </tr>
        <tr>
          <td>Zendesk</td>
          <td>
            _cf_bm
            <br />
            _cfruid
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>
            1 day <br />
            Session
          </td>
          <td>
            These cookies are used to run our customer service Help Centre platform (powered by Zendesk). They perform
            functions including load-balancing, deliverance of website content, distinguishing between humans and bots
            and serving DNS connection for website operators. Find out more from Zendesk's{' '}
            <a href="https://www.zendesk.com/company/agreements-and-terms/cookie-policy/" target="_blank">
              Cookie Policy
            </a>
            .
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default Cookies
