import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdxLayout.tsx";
import CookiesTable from '../components/Cookies';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`“Cookies” are small text files placed on your device (e.g. computer, phone or tablet) when viewing certain pages on our website. Cookies allow us to keep track of some of your browsing preferences and optimise our website for your personal use. Cookies also allow us to automatically track certain information about how you navigate through, and interact with, our website, which helps us to measure our website’s performance and to improve its design and functionality.`}</p>
    <p>{`For more information on cookies, please visit `}<a parentName="p" {...{
        "href": "https://www.allaboutcookies.org/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`www.allaboutcookies.org`}</a>{`.`}</p>
    <h3 {...{
      "id": "we-use-the-following-types-of-cookies"
    }}>{`We use the following types of cookies:`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Strictly necessary cookies.`}</strong>{` These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to make use of e-billing services.`}</li>
      <li parentName="ul"><strong parentName="li">{`Analytical or performance cookies.`}</strong>{` These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.`}</li>
      <li parentName="ul"><strong parentName="li">{`Functionality cookies.`}</strong>{` These are used to recognise you when you return to our website. This enables us to personalise our content for you and remember your preferences (for example, so we can remember your cookie consent preference when you return to our site).`}</li>
      <li parentName="ul"><strong parentName="li">{`Targeting cookies.`}</strong>{` These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests.`}</li>
    </ul>
    <p>{`When you first visit our website, you will be asked for your consent for us to use analytical or targeting cookies. However, we will still use cookies that are strictly necessary for our website to function.`}</p>
    <h3 {...{
      "id": "the-specific-cookies-we-use-are"
    }}>{`The specific cookies we use are:`}</h3>
    <CookiesTable mdxType="CookiesTable" />
    <h3 {...{
      "id": "how-do-i-change-my-cookie-settings"
    }}>{`How do I change my cookie settings?`}</h3>
    <p>{`Web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set, visit `}<a parentName="p" {...{
        "href": "http://www.aboutcookies.org/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`www.aboutcookies.org`}</a>{` or `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`www.allaboutcookies.org`}</a>{`.`}</p>
    <p>{`Find out how to manage cookies on popular browsers:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Google Chrome`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Microsoft Edge`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Mozilla Firefox`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Microsoft Internet Explorer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.opera.com/help/tutorials/security/privacy/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Opera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.apple.com/en-gb/safari",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Apple Safari`}</a></li>
    </ul>
    <p>{`To find information relating to other browsers, visit the browser developer’s website.`}</p>
    <p>{`To opt out of being tracked by Google Analytics across all websites, visit `}<a parentName="p" {...{
        "href": "http://tools.google.com/dlpage/gaoptout",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`tools.google.com/dlpage/gaoptout`}</a>{`.`}</p>
    <p><strong parentName="p">{`Last updated: 22/02/2022`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      